import { Link } from "@remix-run/react";
import { useQuery } from "@tanstack/react-query";
import pluralize from "pluralize";
import type React from "react";
import type { TrendingPost } from "~/types";
import { cn } from "~/util/css";
import { MediaCarouselItem } from "./media-carousel";

interface TrendingPostProps {
  items: number;
  heading?: React.ReactNode;
  cols?: number;
}
export function TrendingPosts(props: TrendingPostProps) {
  const getTrendingPosts = async (): Promise<TrendingPost[]> => {
    try {
      const res = await fetch(`${window.ENV.EDGE_URL}/posts/trending`);
      const data = await res.json();
      return data;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const { isLoading, data } = useQuery({
    queryKey: ["trending-posts"],
    queryFn: getTrendingPosts,
  });
  const trendingPosts = data ? data.slice(0, props.items) : [];

  if (!isLoading && trendingPosts?.length === 0) {
    return (
      <div className="py-10 flex items-center justify-center">
        <p className="text-base">No trending posts yet.</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        {props.heading || (
          <h2 className="font-medium text-[#6637CE]">Trending posts</h2>
        )}
      </div>
      {isLoading ? (
        <SkeletonItem cols={props.cols} />
      ) : (
        <div className={cn(`grid grid-cols-${props.cols || 4} gap-2`)}>
          {trendingPosts?.map((post) => (
            <div
              key={post.post_id}
              className="rounded-lg overflow-hidden group bg-[linear-gradient(118deg,#E0FEF6_27.59%,rgba(127,124,255,0.30)_118.93%)] hover:bg-[#BC4CDE] hover:bg-none p-[1px]"
            >
              <div className="rounded-lg overflow-hidden bg-white w-full h-36 bg-[linear-gradient(122deg,rgba(255,164,220,0.37)_16.13%,rgba(107,104,255,0.30)_98.86%)] group-hover:bg-[linear-gradient(122deg,#987EF7_16.13%,rgba(107,104,255,0.30)_98.86%)]">
                <Link to={`/post/${post.post_id}`}>
                  <div className="h-24">
                    <MediaCarouselItem mediaUrls={post.media_urls} />
                  </div>
                  <div className="h-12 flex-wrap p-2 bg-white bg-opacity-75 flex items-center w-full">
                    <div className="flex gap-1 items-center">
                      <img
                        src={post.avatar_url || "/default-avatar.svg"}
                        alt={post.username}
                        className="w-5 h-5 rounded-full border border-white"
                      />
                      <p className="font-medium text-xs overflow-hidden whitespace-nowrap flex-grow truncate text-black">
                        {post.username}
                      </p>
                    </div>
                    <div className="font-medium text-xs text-slate-600 truncate">
                      {post.sticker_count}{" "}
                      {pluralize("stickers", Number(post.sticker_count))}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export function SkeletonItem(props: { cols?: number }) {
  return (
    <div className={cn(`grid grid-cols-${props.cols || 4} gap-2`)}>
      {Array.from({ length: props.cols || 4 }).map((_, index) => (
        <div
          key={index}
          className="relative rounded-lg overflow-hidden group bg-[rgba(255,255,255,0.30)] hover:bg-[#BC4CDE] hover:bg-none p-[1px] animate-pulse"
        >
          <div className="rounded-lg overflow-hidden bg-gray-300 w-full h-36 animate-pulse">
            <div className="h-24 bg-gray-300" />
            <div className="h-12 flex-wrap p-2 bg-[rgba(255,255,255,0.75)] flex items-center w-full">
              <div className="flex items-center flex-grow gap-2">
                <div className="w-5 h-5 rounded-full bg-gray-300 pl-2" />
                <div className="h-3 bg-gray-300 w-12 animate-pulse" />
              </div>
              <div className="h-3 bg-gray-300 w-24 animate-pulse" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
